<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        title="Загрузка платежного поручения">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
	            <div class="col-12">
		            <form-group-ui
			            invalid-feedback="Выберите файл"
			            label="Выберите файл"
			            required>
			            <input-ui
				            @change="selectFile($event)"
				            type="file"
				            ref="file"
				            :is-invalid="isFileError && state.visibleErrors"
			            />
		            </form-group-ui>
	            </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button-ui
                            button-class="btn-primary">
                            Добавить
                        </button-ui>
                    </div>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
import { insert } from "@/composables/useTransmitter";

export default defineComponent({
    name: "InvoiceModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
	    const supplier = ref([]);
		const file = ref(null)

        const state = reactive({
	        id: 0,
	        file: null,
            visibleErrors: false,
            loader: false,
        });

        const isFileError = computed(() => !state.file)

		function selectFile(e: any) {
			let file = e.target.files[0];
			state.file = file
		}

        function send() {
            if (isFileError.value ) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;

				let fd = new FormData()
	            //@ts-ignore
	            fd.append('file', state.file)
	            fd.append('id', modal.data.payment.rpId)


	            api.requestsPayments.pay(fd).then(r => {
		            modal.ok({
			            item: r.data,
		            });
	            });
            }
        }

	    function ok(loco: any) {
		    modal.ok({
			    loco: loco,
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
	        modal,
            cancel,
            close,
            state,
	        send,
	        isFileError,
	        selectFile
        };
    },
});
</script>

<style scoped lang="scss">

</style>
