<template>
    <modal-ui :id="id" size="modal-lg" title="Редактировать выбор поставщика">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12 col-md-10">
                    <form-group-ui invalid-feedback="Выберите поставщика" label="Поставщик" required>
                        <select-ui :is-invalid="state.visibleErrors && isSupplierIdError" item-key="name"
                            :options="suppliers" :searchable="true" :values="[state.supplierId]"
                            @on-updated="handleSupplierSelect" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-2 d-flex align-items-center">
                    <label class="form-check">
                        <input v-model="state.isVatIncludedInPrice" class="form-check-input" type="checkbox">
                        <span class="form-check-label">НДС</span>
                    </label>
                </div>
                <div class="col-12 row">
                    <div class="col-4 border text-center fw-bold">
                        Материал
                    </div>
                    <div class="col-2 border text-center fw-bold">
                        Кол-во
                    </div>
                    <div class="col-3 border text-center fw-bold">
                        Цена
                    </div>
                    <div class="col-3 border text-center fw-bold">
                        Сумма
                    </div>
                </div>
                <div v-for="materialWithPrice in state.materialsWithPrice" class="col-12 row">
                    <div class="col-4 border d-flex align-items-center">
                        {{ materialWithPrice.material?.materialName }}
                    </div>
                    <div class="col-2 border d-flex align-items-center justify-content-end">
                        {{ materialWithPrice?.quantity }}&nbsp;
                        {{ materialWithPrice?.material?.unit }}
                    </div>
                    <div class="col-3 border">
                        <form-group-ui invalid-feedback="Укажите цену" required>
                            <input-ui class="mt-2 text-end" v-model="materialWithPrice.unitPrice"
                                :is-invalid="state.visibleErrors && isUnitPriceError(materialWithPrice)" type="number"
                                placeholder="Цена" />
                        </form-group-ui>
                    </div>
                    <div class="col-3 border d-flex align-items-center justify-content-end">
                        <b>{{ formatMoney(computeMaterialTotal(materialWithPrice)) }}</b>
                    </div>
                </div>
                <div class="col-12">
                    <div class="fw-bold text-end py-1">
                        Итого: {{ formatMoney(total) }}&nbsp;
                        (без НДС: {{ formatMoney(!state.isVatIncludedInPrice ? total : total / 1.12) }})
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <h6>Условия оплаты</h6>
                    <div class="row">
                        <div class="col-2 border text-center fw-bold">
                            Транш
                        </div>
                        <div class="col-5 border text-center fw-bold">
                            Процент
                        </div>
                        <div class="col-5 border text-center fw-bold">
                            Сумма
                        </div>
                    </div>
                    <div class="row" v-for="tranch in state.paymentTranches" :key="tranch.number">
                        <div class="col-2 border">
                            {{ tranch.number + 1 }}
                        </div>
                        <div class="col-5 border">
                            <form-group-ui invalid-feedback="Сумма процентов траншей должно быть 100" required>
                                <input-ui class="mt-2 text-end" v-model="tranch.percent"
                                    :is-invalid="state.visibleErrors && isTranchesError" type="number"
                                    placeholder="Инфо" />
                            </form-group-ui>
                        </div>
                        <div class="col-5 border d-flex align-items-center justify-content-end">
                            {{ formatMoney(computedTranchTotal(+tranch.percent)) }}
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <form-group-ui invalid-feedback="Условия поставки" label="Условия поставки">
                        <text-area-ui v-model="state.description" placeholder="Условия поставки" />
                    </form-group-ui>
                </div>
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        Сохранить
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import { ISupplier } from "@/@types/ISupplier";
import formatMoney from "@/utils/formatMoney";

type protocolSupplierMaterialType = {
    sum: string;
    total: number;
    quantity: number;
    unitPrice: number;
    groupId: number;
    protocolMaterialId: number;
    material: {
        materialName: string;
        unit: string;
    }
};

type stateType = {
    id: number,
    supplierId: number | string,
    isVatIncludedInPrice: boolean,
    description?: string,
    materialsWithPrice: protocolSupplierMaterialType[],
    paymentTranches: {
        number: number,
        percent: number,
    }[],
    visibleErrors: boolean,
    loader: boolean,
};

export default defineComponent({
    name: "SupplierSelectionModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    methods: {
        formatMoney,
    },
    setup(props) {
        const modal = useModal(props.id);

        const suppliers = ref<ISupplier[]>([]);

        const state: stateType = reactive({
            id: 0,
            supplierId: '',
            isVatIncludedInPrice: false,
            description: '',
            materialsWithPrice: [],
            paymentTranches: [
                {
                    percent: 0,
                    number: 0,
                },
                {
                    percent: 0,
                    number: 1,
                },
                {
                    percent: 0,
                    number: 2,
                },
            ],
            visibleErrors: false,
            loader: false,
        });

        const isSupplierIdError = computed(() => !state.supplierId);
        const isUnitPriceError = computed(() => (material: any) => +material.unitPrice < 0 || !isFinite(+material.unitPrice));
        const isTranchesError = computed(() => state.paymentTranches.reduce((acc, t) => acc + +t.percent, 0) !== 100);

        const computeMaterialTotal = computed(() => (material: any) => {
            return !isUnitPriceError.value(material) && material.quantity ? +material.quantity * +material.unitPrice : 0
        })

        const total = computed(() => state.materialsWithPrice.reduce((acc, mp) => {
            if (isUnitPriceError.value(mp) || !+mp.quantity) {
                return acc
            }
            return acc + (+mp.quantity * +mp.unitPrice);
        }, 0));

        const computedTranchTotal = computed(() => (percent: number) => total.value && percent ? total.value * (percent / 100) : 0);

        onMounted(() => {
            if (Object.values(modal.data?.protocolSupplier || {}).length) {
                state.supplierId = modal.data.protocolSupplier.supplierId;
                state.description = modal.data.protocolSupplier.description || '';
                state.isVatIncludedInPrice = modal.data.protocolSupplier.isVatIncludedInPrice;
                state.materialsWithPrice = modal.data.protocolSupplier.materialsWithConditions.map((m: any) => ({
                    ...m,
                }));
                state.paymentTranches = modal.data.protocolSupplier?.paymentTranches.length
                    ? modal.data.protocolSupplier?.paymentTranches.map((pt: any) => ({ number: pt.number, percent: pt.tranchPercent }))
                    : [0, 1, 2].map(number => ({ number, percent: 0 }));
            }
            if (modal.data?.suppliers?.length) {
                suppliers.value = modal.data?.suppliers;
            }
        });

        function handleSupplierSelect(m: any) {
            state.supplierId = m.id;
        }

        const isErrorsExist = computed(() => {
            return state.materialsWithPrice.some(rm => isUnitPriceError.value(rm)) || isSupplierIdError.value || isTranchesError.value;
        });

        function send() {
            if (isErrorsExist.value) {
                state.visibleErrors = true;
                return;
            }
            state.visibleErrors = false;
            state.loader = true;

            api.supplierSelection.updateSupplier({
                protocolSupplierId: modal.data.protocolSupplier.id,
                supplierId: state.supplierId,
                isVatIncludedInPrice: state.isVatIncludedInPrice,
                description: state.description,
                materialsWithPrice: state.materialsWithPrice,
                paymentTranches: state.paymentTranches,
            }).then(r => {
                modal.ok(r.data);
            })
        }

        function ok(loco: any) {
            modal.ok({
                loco: loco,
            });
        }

        function close() {
            modal.cancel({
                zxc: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok,
            modal,
            cancel,
            close,
            state,
            send,
            isSupplierIdError,
            isUnitPriceError,
            computeMaterialTotal,
            computedTranchTotal,
            isTranchesError,
            suppliers,
            total,
            handleSupplierSelect,
        };
    },
});
</script>

<style scoped lang="scss"></style>
