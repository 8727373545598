<template>
    <modal-ui :id="id" size="modal-xl" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4" v-for="(requiredMaterial, requiredMaterialIdx) in state.requiredMaterials"
                :key="requiredMaterialIdx">
                <div class="col-12 col-md-3">
                    <form-group-ui invalid-feedback="Укажите наименование ТМЦ" label="Наименование ТМЦ" required>
                        <select-ui :is-invalid="state.visibleErrors && isMaterialIdError(requiredMaterial)"
                            :options="materials" placeholder="Наименование ТМЦ" :searchable="true"
                            :values="[requiredMaterial.materialId]"
                            @on-selected="(value: any) => onMaterialSelected(value, requiredMaterialIdx)" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-1">
                    <div>
                        <label for="formFile" class="form-label">Ед изм</label>
                        <input class="form-control" :value="getUnitDimensionOfMaterial(requiredMaterial)" disabled>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <form-group-ui invalid-feedback="Укажите конструктив" label="Конструктив" required>
                        <select-ui :is-invalid="state.visibleErrors && isConstructIdError(requiredMaterial)"
                            :options="constructs" placeholder="Конструктив" :searchable="true"
                            :values="[requiredMaterial.constructId]"
                            @on-selected="(value: any) => onConstructSelected(value, requiredMaterialIdx)" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-2">
                    <form-group-ui invalid-feedback="Укажите количество" label="Количество" required>
                        <input-ui v-model="requiredMaterial.count"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ } } }"
                            :is-invalid="state.visibleErrors && isCountError(requiredMaterial)"
                            placeholder="Количество" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-2">
                    <form-group-ui invalid-feedback="Укажите примечание" label="Примечание">
                        <input-ui v-model="requiredMaterial.comment" placeholder="Примечание" />
                    </form-group-ui>
                </div>
                <div v-if="state.requiredMaterials.length > 1" class="col-auto flex-grow-0 flex-shrink-0">
                    <div class="mb-3">
                        <label class="form-label d-block" style="color: #fff">.</label>
                        <button-ui class="btn-outline-danger" type="button"
                            @click="deleteRequiredMaterial(requiredMaterialIdx)">
                            x
                        </button-ui>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <button-ui class="btn-outline-secondary" type="button" @click="addRequiredMaterial">
                        Добавить материал
                    </button-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts" async setup>
import { onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { useRoute } from "vue-router";
import { IMaterial } from "@/@types/IMaterial";

const props = defineProps({
    id: Number,
});

const modal = useModal(String(props.id));
const route = useRoute();

const materials = ref<IMaterial[]>([]);
const constructs = ref([]);

const state = reactive({
    title: '',
    btn: '',
    id: 0,
    requiredMaterials: [{
        count: '',
        comment: '',
        materialId: 0,
        constructId: 0,
    }],
    visibleErrors: false,
    loader: false,
});

const isMaterialIdError = computed(() => (requiredMaterial: any) => !requiredMaterial.materialId);
const isConstructIdError = computed(() => (requiredMaterial: any) => !requiredMaterial.constructId);
const isCountError = computed(() => (requiredMaterial: any) => +requiredMaterial.count <= 0);
const getUnitDimensionOfMaterial = computed(() => (requiredMaterial: any) => {
    if (!requiredMaterial.materialId) {
        return '-';
    }
    const material = materials.value.find((i: any) => i.id === requiredMaterial.materialId);
    if (!material) {
        return '-';
    }
    return material.measurement;
})

const errors = computed(() => {
    return state.requiredMaterials.some(rm => {
        return isMaterialIdError.value(rm) || isConstructIdError.value(rm) || isCountError.value(rm);
    });
});

onMounted(() => {
    state.title = 'Добавление';
    state.btn = 'Добавить';

    api.constructs.get().then(r => constructs.value = r.data);
    api.materials.getOld().then(r => materials.value = r.data);
});

function send() {
    if (errors.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;
    api.apMaterial.create(+route.params.id, {
        requiredMaterials: state.requiredMaterials.map(rm => {
            return {
                count: rm.count,
                comment: rm.comment.length ? rm.comment : null,
                materialId: rm.materialId,
                subgroupId: rm.constructId,
                kind: parseInt(modal.data.kind)
            };
        }),
    }).then((r: any) => {
        if (r.data) {
            modal.ok(r.data);
        }
    }).finally(() => state.loader = false);
}

function onMaterialSelected(_m: any, idx: number) {
    state.requiredMaterials[idx].materialId = _m.id;
}

function onConstructSelected(_c: any, idx: number) {
    state.requiredMaterials[idx].constructId = _c.id;
}

function deleteRequiredMaterial(idx: number) {
    state.requiredMaterials.splice(idx, 1);
}

function addRequiredMaterial() {
    state.requiredMaterials.push({
        count: '',
        comment: '',
        materialId: 0,
        constructId: 0,
    });
}

function ok() {
    modal.ok({
        asd: 123,
    });
}

function close() {
    modal.close();
}

function cancel() {
    modal.cancel({
        zxc: 123,
    });
}

</script>

<style scoped lang="scss"></style>
