<template>
  <modal-ui :id="id" size="modal-lg" title="Себестоимость проекта">
    <form class="loader" @submit.prevent="send">
      <loader-ui :is-show="state.loader" />
      <div class="row my-2">
        <div class="col-6 border fw-bold">Конструктив</div>
        <div class="col-6 border fw-bold">Сумма без НДС</div>
      </div>
      <div
        v-for="constructivePrice in state.constructivePrices"
        class="row my-2"
        :key="constructivePrice.constructiveId"
      >
        <div class="col-6 border d-flex align-items-center">
          {{ constructivePrice.constructiveName }}
        </div>
        <div class="col-6 border">
          <form-group-ui invalid-feedback="Укажите сумму" required>
            <input-ui
              class="mt-2 text-end"
              v-model="constructivePrice.totalWithoutVAT"
              :mask="{ mask: 'Z*', tokens: { Z: { pattern: /[0-9]/ } } }"
              :is-invalid="state.visibleErrors && isTotalWithoutVATError(constructivePrice)"
              maxlength="12"
            />
          </form-group-ui>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-6 border fw-bold">Итого</div>
        <div class="col-6 border fw-bold">
          {{ formatMoney(total) }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button-ui button-class="btn-primary"> Сохранить </button-ui>
        </div>
      </div>
    </form>
  </modal-ui>
</template>

<script async setup lang="ts">
import { onMounted, reactive, computed, ref } from 'vue';
import { useModal } from '@/composables/useModal';
import api from '@/api';
import { IConstructive } from '@/@types/ISummary';
import formatMoney from '@/utils/formatMoney';

type constructivePriceType = {
  constructiveId: number;
  constructiveName: string;
  constructiveNumber: string;
  projectRegisterId: number;
  totalWithoutVAT: number;
};

type stateType = {
  constructivePrices: Array<constructivePriceType>;
  visibleErrors: boolean;
  loader: boolean;
};

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
});

const modal = useModal(props.id);

const state: stateType = reactive({
  constructivePrices: [],
  visibleErrors: false,
  loader: false,
});

const constructives = ref<IConstructive[]>([]);

const isTotalWithoutVATError = computed(() => (constructivePrice: constructivePriceType) => {
  return +constructivePrice.totalWithoutVAT < 0 || !isFinite(+constructivePrice.totalWithoutVAT);
});

const total = computed(() =>
  state.constructivePrices.reduce((acc, mp) => {
    if (isTotalWithoutVATError.value(mp)) {
      return acc;
    }
    return acc + +mp.totalWithoutVAT;
  }, 0)
);

const errors = computed(() => state.constructivePrices.some((cp) => isTotalWithoutVATError.value(cp)));

onMounted(() => {
  api.constructs.get().then((r: any) => {
    constructives.value = r.data;

    if (modal.data.projectRegister.constructivePrices?.length) {
      state.constructivePrices = modal.data.projectRegister.constructivePrices.map((cp: any) => ({
        totalWithoutVAT: +cp.totalWithoutVAT,
        constructiveId: cp.constructiveId,
        constructiveName: `${cp.constructive.nm} ${cp.constructive.group_name}`,
        constructiveNumber: cp.constructive.nm,
        projectRegisterId: modal.data.projectRegister.id,
      }));
    } else {
      state.constructivePrices = r.data.map((constructive: IConstructive) => ({
        constructiveId: constructive.id,
        constructiveName: constructive.name,
        constructiveNumber: constructive.nm,
        projectRegisterId: modal.data.projectRegister.id as number,
        totalWithoutVAT: 0,
      }));
    }
  });

});

function send() {
  if (errors.value) {
    state.visibleErrors = true;
    return;
  }
  state.visibleErrors = false;
  state.loader = true;

  api.projectRegister
    .calculate(modal.data.projectRegister.id, { constructivePrices: state.constructivePrices })
    .then(() => modal.ok({ isSuccess: true }))
    .finally(() => (state.loader = false));
}
</script>

<style scoped lang="scss"></style>
